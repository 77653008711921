import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import router from './router'
import '@css/index.css'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n 英文
Vue.$message = Element.Message
Vue.use(Element, {locale})

import common from '@/utils/common.js';
Vue.prototype.$common = common;

Vue.directive('removeAriaHidden', {
	bind(el, binding) {
		let ariaEls = el.querySelectorAll('.el-radio__original');
		ariaEls.forEach((item) => {
			item.removeAttribute('aria-hidden');
		});
	}
});

new Vue({
	router,
    render: h => h(App),
}).$mount('#app')
