
//时间戳转时间
const timestampToTime = (timestamp) => {
	if(!timestamp) return ""
	var date = new Date(timestamp + 8 * 3600 * 1000); // 增加8小时
	return date.toJSON().substr(0, 19).replace('T', ' ');
}


export default {
	timestampToTime
}