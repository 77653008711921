import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

//push 
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
}

const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
	return VueRouterReplace.call(this, to).catch(err => err)
}

const router = new VueRouter({
	routes: [{
			path: '/',
			redirect: '/index'
		},
		{
			path: '/index',
			name: 'Index',
			redirect: '/home',
			component: () => import('@/views/index.vue'),
			children: [{
				path: '/home',
				name: 'Home',
				component: () => import('@/views/pages/home.vue')
			}, {
				path: '/searchList',
				name: 'SearchList',
				component: () => import('@/views/pages/searchList.vue')
			}, {
				path: '/virusDetail/:id',
				name: 'VirusDetail',
				component: () => import('@/views/pages/virusDetail.vue')
			}, {
				path: '/geneContrast',
				name: 'GeneContrast',
				component: () => import('@/views/pages/geneContrast.vue')
			}, {
				path: '/sequenceRetrievalDetail',
				name: 'SequenceRetrievalDetail',
				component: () => import('@/views/pages/sequenceRetrievalDetail.vue')
			}]
		},
		{
			path: '/404',
			component: () => import('@/views/404.vue')
		},
		{
			path: '*',
			redirect: '/404'
		}
	]
})

// 路由跳转前
router.beforeEach((to, from, next) => {
	// 可用于拦截导航并执行一些操作，例如验证用户身份、权限控制等。
	to.meta.title && (document.title = to.meta.title);
	next()
})

// 路由跳转后
router.afterEach((to, from) => {
	window.scrollTo(0, 0) // 每次路由改变滚动条都回到顶部
})

export default router